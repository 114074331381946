<template>
  <main class="in-profile-invoice-single mb-5">
    <div class="mt-0 mt-md-4 container">
      <b-card class="border-radius15 border-0 box-shaddow20" no-body>
        <div class="container  px-2 px-md-4 border-radius15">
          <div id="print" v-if="invoice" class="order-detail pb-4">
            <img class="print-only" src="../../assets/images/albaLogo.png" alt="لوگو لبنوس">
            <h5 class="mt-4 order-detail-title text-primary">
              جزئیات فاکتور {{ invoice.invoice_number }} <InvoiceBadge :status="invoice.status.status"></InvoiceBadge>
              <button class="btn btn-secondary not-in-print" style="padding: 3px 9px;margin-right: 5px;" @click="print">
                <span class="ml-1">پرینت</span>
                <svg enable-background="new 0 0 50 50" height="20px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="20px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><rect fill="none" height="50" width="50"/><circle cx="43" cy="21" r="2"/><path d="M40,15V1H10v14" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><path d="M40,29v20H10V29H40z" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><path d="M10,40H3  c-1.104,0-2-0.896-2-2V17c0-1.104,0.896-2,2-2h44c1.104,0,2,0.896,2,2v21c0,1.104-0.896,2-2,2h-7" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><line fill="none" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" x1="35" x2="15" y1="35" y2="35"/><line fill="none" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" x1="31" x2="15" y1="39" y2="39"/><line fill="none" stroke="#ffffff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" x1="35" x2="15" y1="43" y2="43"/></svg>
              </button>
            </h5>
            <div class="orders mt-3">
              <div class="mt-3 order-list">
                <table class="table mb-0 table-striped table-responsive  d-md-table ">
                  <thead class="w-100">
                    <tr>
                      <th scope="col">محصول</th>
                      <th scope="col">قیمت</th>
                      <th scope="col">تخفیف (درصد)</th>
                      <th scope="col">قیمت پس از تخفیف </th>
                      <th scope="col">تعداد</th>
                      <th scope="col">قیمت کل </th>
                    </tr>
                  </thead>
                  <tbody class="w-100">
                    <tr v-for="(order) in invoice.orders" :key="order.id">
                      <td class="full-title">
                        <router-link tag="a" class="cursor-pointer" :to="'/product/' + order.variety.product.id">
                          <div>
                            <img class="rounded-circle" :src="order.variety.image" />
                          </div>
                          <div>
                            {{order.variety.fullTitle}}
                          </div>
                        </router-link>
                      </td>
                      <td v-if="order.base_price"> {{order.base_price | price}}</td>

                      <td v-if="order.discount"> {{order.discount}}</td>
                      <td v-else> - </td>
                      <td v-if="order.total"> {{order.total | price}}</td>
                      <td> {{order.quantity}} </td>
                      <td v-if="order.amount" class="weight-bold"> {{order.amount | price}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <section class="pt-4 off-code d-flex justify-content-center">
                  <div class="d-flex border-radius-10 my-2 my-md-0 p-2 bg-light align-items-center mx-2 invoice">
                    <h4 class="invoice-txt">جمع کل :</h4>
                    <h4 class="mr-1 invoice-num">
                      <span
                        class="dark-txt">{{ ( invoice.SumTotalOrders ? invoice.SumTotalOrders  : 0 ) | price }}</span>
                      <!-- <span class="light-txt pe-1">تومان</span> -->
                    </h4>
                  </div>
                  <div class="d-flex border-radius-10 my-2 my-md-0 p-2 bg-light align-items-center mx-2 invoice">
                    <h4 class="invoice-txt">هزینه ارسال :</h4>
                    <h4 class="mr-1 invoice-num" v-if="invoice.shipping_method == 'tipax'">
                      <span class="dark-txt">پرداخت درب منزل</span>
                    </h4>
                    <h4 class="mr-1 invoice-num" v-else-if="invoice.delivery_amount != '0'">
                      <span class="dark-txt"> {{ invoice.delivery_amount | price}}</span>
                      <!-- <span class="light-txt pe-1">تومان</span> -->
                    </h4>
                    <h4 class="mr-1 invoice-num" v-else>
                      <span class="dark-txt">رایگان</span>
                    </h4>
                  </div>
                  <div class="d-flex border-radius-10 my-2 my-md-0 p-2 bg-light align-items-center mx-2 invoice">
                    <h4 class="invoice-txt">ارسال توسط :</h4>
                    <h4 class="mr-1 invoice-num">
                      <span v-if="invoice.shipping_method == 'post'">پست معمولی</span>
                      <span v-else-if="invoice.shipping_method == 'pishtaz'">پست پیشتاز</span>
                      <span v-else-if="invoice.shipping_method == 'tipax'">پست تیپاکس</span>
                    </h4>
                  </div>
                  <div v-if="invoice.discount"
                    class="d-flex border-radius-10 my-2 my-md-0 p-2 bg-light align-items-center mx-2 invoice">
                    <h4 class="invoice-txt">تخفیف :</h4>
                    <h4 class="mr-1 invoice-num">
                      <span class="dark-txt">
                        {{invoice.discount}} %
                      </span>
                      <!-- <span class="light-txt ">تومان</span> -->
                    </h4>
                  </div>
                  <div class="d-flex border-radius-10 my-2 my-md-0 p-2 bg-light align-items-center mx-2 invoice">
                    <h4 class="invoice-txt">مبلغ پرداختی :</h4>
                    <h4 class="mr-1 invoice-num">
                      <span v-if="invoice.AmountOrders" class="dark-txt">
                        {{  (invoice.AmountOrders ? invoice.AmountOrders : 0) | price }}
                      </span>
                      <!-- <span class="light-txt ">تومان</span> -->
                    </h4>
                  </div>
                </section>
              </div>
              <div class="print-only" style="margin-top: 2rem;">
                <p>با تشکر از خرید شما!</p>
              </div>

            </div>
          </div>
          <div class="mt-3" v-else>
            <b-skeleton style="max-width: 170px;margin-bottom: 20px"></b-skeleton>
            <b-skeleton-table></b-skeleton-table>
          </div>
        </div>
      </b-card>
    </div>
  </main>
</template>

<script>
  import InvoiceBadge from "./component/profile/InvoiceBadge";

  export default {
    name: "ProfileInvoiceSingle",
    components: {
      InvoiceBadge
    },
    data() {
      return {
        invoice: null
      }
    },
    mounted() {
      this.loadInvoice(this.$route.params.id)
    },
    methods: {
      async loadInvoice(id) {
        this.$store.commit('showLoading', false)
        try {
          let response = await this.$axios.get('api/profile/detail-orders/' + id);
          this.invoice = response.data.data
        } catch (error) {
          this.$root.error_notification(error)
        }
        this.$store.commit('hideLoading', false)
      },
      print() {
        print();
      }
    }
  }
</script>

<style scoped>
  .order-list,
  .orders-list-table {
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow: hidden;
    border: 1px solid #f1f1f1;

  }

  .order-detail .invoice .invoice-num {
    font-size: 14px;
  }

  .order-detail .invoice .invoice-txt {
    font-size: 13px;
  }

  .order-detail-title {
    text-align: right;
  }

  td {
    text-align: right;
  }

  th {
    text-align: right;
  }

  .full-title {
    max-width: 250px;
  }

  table {
    color: #545454;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(97 95 203 / 5%);
  }

  .invoice-txt {
    font-weight: bold;
    color: #0a001f;
  }

  .full-title img {
    width: auto;
    display: inline-block;
  }

  .full-title>a {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 5px;
  }

  @media screen and (max-width: 500px) {
    .full-title img {
      display: none;
    }

    .full-title>div {
      display: block;
    }
  }
</style>
<style>
.print-only {
  display: none;
}
@media print {
  .print-only {
    display: block!important;
  }
  body * {
    visibility: hidden;
  }
  #print, #print * {
    visibility: visible;
    font-size: 1.25rem;
  }
  #print {
    position: fixed;
    left: 0;
    top: 0;
  }
  #print {
    width: 100%;
  }

  #print .not-in-print {
    display: none;
  }
}
</style>
