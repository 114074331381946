<template>
  <span v-if="status == 0" class="badge bg-danger text-white p-2">جدید</span>
  <span v-else-if="status == 1" class="badge  bg-warning p-2">در حال بررسی</span>
  <span v-else-if="status == 2" class="badge bg-primary text-white p-2">تائید شده</span>
  <span v-else-if="status == 3" class="badge bg-info  p-2">در حال ارسال</span>
  <span v-else-if="status == 4" class="badge bg-success text-white p-2">تحویل داده شده</span>
  <span v-else-if="status == 5" class="badge bg-danger text-white p-2">کنسل شده</span>
</template>

<script>
export default {
  name: "InvoiceBadge",
  props: ['status']
}
</script>

<style scoped>

</style>